<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handelAdd" icon="el-icon-plus" type="primary"
                           class="hidden-sm-only hidden-sm-only" v-has="'create_expert'">添 加
                </el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh"
                           class="hidden-sm-only hidden-sm-only">刷 新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="专家库信息"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入类型名称" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search" v-has="'expert_search'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data"  stripe size="small" v-loading="loading" width="100%" height="100%"
                          border>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="num" label="专家编号" width="" show-overflow-tooltip/>
                    <el-table-column prop="name" label="专家姓名" width="" show-overflow-tooltip/>
                    <el-table-column prop="sex" label="性别" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.sex == '2'" class="value"> 女 </span>
                            <span v-if="scope.row.sex == '1'" class="value"> 男 </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="birthday" label="出生日期" width="" show-overflow-tooltip/>
                    <el-table-column prop="education" label="文化程度" width="" show-overflow-tooltip >
                        <template slot-scope="scope">
                            <span>{{scope.row.education|educationFormatter}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="degree" label="学位" width="" show-overflow-tooltip >
                        <template slot-scope="scope">
                            <span v-if="scope.row.degree=='1'">学士</span>
                            <span v-else-if="scope.row.degree=='2'">研究生</span>
                            <span v-else-if="scope.row.degree=='3'">博士</span>
                            <span v-else>其他</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="毕业院校及时间" width="200px" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.almaMater }}</span>
                            </div>
                            <div><small> {{ scope.row.graduationDate }}</small>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hospital" label="执业医院" width="" show-overflow-tooltip/>
                    <el-table-column prop="department" label="执业科室" width="" show-overflow-tooltip/>
                    <el-table-column prop="jobLevel" label="职务职级" width="" show-overflow-tooltip/>
                    <el-table-column  label="从业年限及主要经验" width="200px"  show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.duration }}</span>
                            </div>
                            <div><small> {{ scope.row.workExperience }}</small>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="180" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text"  size="mini" @click="handleUpdate(scope.row)" v-has="'expert_edit'">
                                编辑详情
                            </el-button>
                            <el-button type="text" size="mini" @click="handleDelete(scope.row)" v-has="'expert_del'">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="vmodel"></data-form>
    </div>
</template>

<script>
    import dataForm from './widgets/dataform'

    export default {
        components: {
            dataForm,
        },
        data() {
            return {
                loading: false,
                search: {},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                dialogVisible: false,
                form: {},
                isFullIdCard: []
            }
        },
        filters: {
            educationFormatter: function (value) {
                if (!value) {
                    return "无"
                }
                const items = [
                    {'label':"研究生及以上","value":"1","id":1},
                    {'label':"大学本科","value":"2","id":2},
                    {'label':"大学专科和专科学校","value":"3","id":3},
                    {'label':"中等专业学校","value":"4","id":4},
                    {'label':"技工学校","value":"5","id":5},
                    {'label':"高中","value":"6","id":6},
                    {'label':"初中","value":"7","id":7},
                    {'label':"小学","value":"8","id":8},
                    {'label':"文盲或半文盲","value":"9","id":9},
                    {'label':"不详","value":"10","id":10},
                ]
                const item = items.find(item => item.value === value);
                return item ? item.label : "无"
            },
        },
        methods: {
            handelAdd(){
                this.form = {}
                this.dialogVisible = true
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.name} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/company/expert/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogVisible = true
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    this.$http.get(`/company/expert?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                            this.loading = false
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
