<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="150px" size="mini" :rules="rules">
            <div style="height: 450px;overflow: auto">
                <el-row>
                    <el-col :span="16">
                        <el-form-item label="编号：" prop="num">
                            <el-input v-model="form.num" />
                        </el-form-item>
                        <el-form-item label="专家名称：" prop="name">
                            <el-input v-model="form.name" />
                        </el-form-item>
                        <el-form-item label="性别：" prop="sex">
                            <el-select v-model="form.sex" placeholder="请选择" style="width: 100%">
                                <el-option label="男" value="1"></el-option>
                                <el-option label="女" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="出生日期：" prop="birthday">
                            <el-date-picker type="date" v-model="form.birthday" value-format="yyyy-MM-dd"
                                style="width: 100%" placeholder="请选择">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" style="text-align: center;">
                        <upload-image v-model="form.photo" />
                    </el-col>
                </el-row>
                <el-form-item label="文化程度：" prop="education">
                    <el-select v-model="form.education" placeholder="请选择" style="width: 100%">
                        <el-option v-for="item in educationList" :key="item.id" :label="item.label" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学位：" prop="degree">
                    <el-select v-model="form.degree" placeholder="请选择" style="width: 100%">
                        <el-option label="学士" value="1"></el-option>
                        <el-option label="研究生" value="2"></el-option>
                        <el-option label="博士" value="3"></el-option>
                        <el-option label="其他" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="毕业院校：" prop="almaMater">
                    <el-input v-model="form.almaMater" />
                </el-form-item>
                <el-form-item label="毕业时间：" prop="graduationDate">
                    <el-date-picker type="date" v-model="form.graduationDate" value-format="yyyy-MM-dd"
                        style="width: 100%" placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="执业医院：" prop="hospital">
                    <el-input v-model="form.hospital" />
                </el-form-item>
                <el-form-item label="执业科室：" prop="department">
                    <el-input v-model="form.department" />
                </el-form-item>
                <el-form-item label="职务级别：" prop="jobLevel">
                    <el-input v-model="form.jobLevel" />
                </el-form-item>
                <el-form-item label="从业年限：" prop="duration">
                    <el-input v-model="form.duration" />
                </el-form-item>
                <el-form-item label="主要工作经验：" prop="workExperience">
                    <el-input v-model="form.workExperience" type="textarea" :rows="3" />
                </el-form-item>
            </div>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        title: {
            default: "添加/修改"
        },
    },
    data() {
        return {
            innerVisible: false,
            educationList: [
                { 'label': "研究生及以上", "value": "1", "id": 1 },
                { 'label': "大学本科", "value": "2", "id": 2 },
                { 'label': "大学专科和专科学校", "value": "3", "id": 3 },
                { 'label': "中等专业学校", "value": "4", "id": 4 },
                { 'label': "技工学校", "value": "5", "id": 5 },
                { 'label': "高中", "value": "6", "id": 6 },
                { 'label': "初中", "value": "7", "id": 7 },
                { 'label': "小学", "value": "8", "id": 8 },
                { 'label': "文盲或半文盲", "value": "9", "id": 9 },
                { 'label': "不详", "value": "10", "id": 10 },
            ],
            rules: {
                name: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                sex: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                workExperience: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                jobLevel: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                duration: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                department: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                hospital: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                graduationDate: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                almaMater: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                degree: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                birthday: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                num: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                education: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],

            }
        }
    },
    methods: {
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = `/company/expert`
                const resp = await this.$http.post(url, this.form)
                this.$message[resp.data.status](resp.data.msg)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.tijian {
    border: 1px solid #c0c4cc;
    padding: 5px;
}

.title {
    display: flex;
    align-items: center;
    padding: 10px;

    .title-text {
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
        border-top: 1px solid #EBEEF5;
        padding: 10px;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 6px 0;
}

.flex {
    display: flex;
}

.btn {
    justify-content: space-between;
    padding: 10px 0;
}

.border-bottom {
    border-bottom: 1px solid #EBEEF5;
}

.text-center {
    text-align: center;
    padding: 5px 0;
}

.align-items {
    align-items: center;
}

.text-group {
    display: grid;
    padding-right: 10px
}
</style>
